import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import { Heading, Title, TwoColContainer, Col1Image, Col2Info, Text, NavLink  } from '../components/Layout/pageParts';
import Layout from "../components/Layout/layout"

const OrderCompletePage = () => {

    const data = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "bg3.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }
    }
`)

    return (    
        <Layout>
          <TwoColContainer>
            <Col1Image>
              <Img alt="thanks" fluid={data.fileName.childImageSharp.fluid} style={{ width: "100%", height: "100%" }} imgStyle={{ objectPosition: 'left' }} />
            </Col1Image>
            <Col2Info>
              <Heading>Thanks for your order!</Heading>
              <Title>I'll be in touch very soon</Title>
              <Text>
                We appreciate your business!
                If you have any questions, please feel free to <NavLink to="/contact">contact us</NavLink>                
            </Text>
            </Col2Info>
          </TwoColContainer>
        </Layout>    
)};

export default OrderCompletePage;